






























import { DynamicProperty } from '@mtap-smartcity/api/src/lib/models/telemetries.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SemiCircleGauge from '../../dataDisplayComponents/SemiCircleGauge.vue';
import ClassicMeter from '../../dataDisplayComponents/ClassicMeter.vue';

@Component({
  components: {
    ClassicMeter,
    SemiCircleGauge
  }
})
/**
 * @group Analytics Card
 * Card with semi circle gauges for displaying index values withing min and max ranges
 */
export default class DynamicTelemetryCard extends Vue {
  @Prop({
    type: Array,
    required: true
  }) readonly parameters!: DynamicProperty[];
}
