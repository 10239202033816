






















import { Component, Prop, Vue } from 'vue-property-decorator';
import DynamicTelemetryCard from './DynamicTelemetryCard.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import { Models } from '@mtap-smartcity/api';

@Component({
  components: {
    BaseSelect,
    DynamicTelemetryCard,
  }
})
/**
 * @group Analytics Card
 * Card with semi circle gauges for displaying index values withing min and max ranges
 */
export default class GatewayMtap7V1ControllerTelemetryCard extends Vue {
  @Prop({
    required: true,
    type: Array
  }) readonly telemetries!: Models.Telemetries.DynamicSubDeviceProperties[];

  selectedDevice: number = 0;

  get devices() {
    return this.telemetries.map(({
      device_name,
      position
    }) => ({
      text: device_name,
      value: position
    }));
  }

  get device() {
    return this.telemetries.find((t) => t.position === this.selectedDevice);
  }
}
